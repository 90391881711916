<script>
// import Slider from "@vueform/slider";
import Multiselect from "@vueform/multiselect";
import Swal from "sweetalert2";

import Axios from "@/config/axios";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import "@vueform/multiselect/themes/default.css";

import PageHeader from "@/components/page-header";

import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import store from "../../../state/store";

export default {
  page: {
    title: "Produits",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Produits",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Produits",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      sliderCustomzie: [1000, 3000],
      productsData: [],
      pages: [],
      page: 1,
      perPage: 10,
      value: ["Watches", "Headset"],
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.productsData);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.paginate(
          this.productsData.filter((data) => {
            return (
              data.name.toLowerCase().includes(search) ||
              data.category.includes(search) ||
              data.price.toString().toLowerCase().includes(search) ||
              data.publishedDate[0].toLowerCase().includes(search)
            );
          })
        );
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    productsData(newValue) {
      this.setPages(newValue);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        this.setPages(
          this.productsData.filter((data) => {
            return (
              data.name.toLowerCase().includes(search) ||
              data.category.includes(search) ||
              data.price.toString().toLowerCase().includes(search) ||
              data.publishedDate[0].toLowerCase().includes(search)
            );
          })
        );
      } else this.setPages(this.productsData);
    },
  },
  created() {
    this.setPages(this.productsData);
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  async beforeRouteEnter() {
    store.dispatch("setGlobalLoading", true);
    const productsData = [];
    await Axios.get("/admin/products")
      .then((data) => {
        const monthNames = [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aout",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];
        data.data.data.forEach((row) => {
          var dd = new Date(row.publishedDate);
          row.publishedDate =
            dd.getDate() +
            " " +
            monthNames[dd.getMonth()] +
            ", " +
            dd.getFullYear();
          var hours = dd.getHours();
          var minutes = dd.getMinutes();
          var ampm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          hours = hours < 10 ? "0" + hours : hours;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          var strTime = hours + ":" + minutes + " " + ampm;
          row.publishedtime = strTime;
          // row.image_src =
          //   "https://api-node.themesbrand.website/images/products/" + row.image;
          row.image_src = row.image;
          // row.image_src = `@/assets/images/products/img-8.png`;
          productsData.push(row);
        });
      })
      .catch((er) => {
        console.log(er);
      })
      .finally(() => {
        store.dispatch("setGlobalLoading", false);
      });

    localStorage.setItem("@LIST_PRODUCTS", JSON.stringify(productsData));
  },
  beforeMount() {
    this.productsData = JSON.parse(
      localStorage.getItem("@LIST_PRODUCTS") || "[]"
    );
  },

  methods: {
    deleteMultiple() {
      let ids_array = [];
      var items = document.getElementsByName("chk_child");
      items.forEach(function (ele) {
        if (ele.checked == true) {
          var trNode = ele.parentNode.parentNode;
          var id = trNode.querySelector(".id a").innerHTML;
          ids_array.push(id);
        }
      });
      if (typeof ids_array !== "undefined" && ids_array.length > 0) {
        if (confirm("Are you sure you want to delete this?")) {
          var cusList = this.productsData;
          ids_array.forEach(function (id) {
            cusList = cusList.filter(function (orders) {
              return orders.id != id;
            });
          });
          this.productsData = cusList;
          document.getElementById("checkAll").checked = false;
          var itemss = document.getElementsByName("chk_child");
          itemss.forEach(function (ele) {
            if (ele.checked == true) {
              ele.checked = false;
              ele.closest("tr").classList.remove("table-active");
              document.getElementById("selection-element").style.display =
                "none";
            }
          });
        } else {
          return false;
        }
      } else {
        Swal.fire({
          title: "Veuillez cocher au moins une case",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    setPages(data) {
      this.pages = [];
      let numberOfPages = Math.ceil(data.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    deletedata(event) {
      Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Vous ne pourrez pas revenir en arrière !",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Oui, supprimez-le !",
      }).then((result) => {
        if (result.value) {
          this.productsData.splice(this.productsData.indexOf(event), 1);
          Axios.delete(`/admin/products/${event._id}`)
            .then(() => {})
            .catch((er) => {
              console.log(er);
            });
          Swal.fire("Supprimé !", "Votre produit a été supprimé.", "success");
        }
      });
    },
    paginate(productsData) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return productsData.slice(from, to);
    },
    handleDeleteMany() {
      const ids = [];
      const list = document.getElementsByClassName("check-item");
      for (let index = 0; index < list.length; index++) {
        const element = list.item(index);
        if (element.checked) {
          ids.push(
            Number(
              element.parentElement.parentElement.children.item(1).innerText
            )
          );
        }
      }

      this.productsData = this.productsData.filter((p) => !ids.includes(p.id));

      Axios.delete(`/admin/products/${ids.join("-")}`)
        .then(() => {})
        .catch((er) => {
          console.log(er);
        });
      Swal.fire("Supprimé !", "Vos produits ont été supprimé.", "success");

      document.getElementById("selection-element").style.display = "none";
      document.getElementById("select-content").innerHTML = 0;
    },
  },
  mounted() {
    var total = 0;
    var checkchild = document.querySelectorAll(
      '.form-check-all input[type="checkbox"]'
    );
    checkchild.forEach(function (checkbox) {
      checkbox.onclick = function () {
        total = 0;
        checkchild.forEach(function (box) {
          if (box.checked == true) {
            total++;
          }
        });
        if (checkbox.checked == true) {
          checkbox.closest("tr").classList.add("table-active");
          document.getElementById("selection-element").style.display = "block";
          document.getElementById("select-content").innerHTML = total;
        } else {
          if (total <= 0) {
            document.getElementById("selection-element").style.display = "none";
          }
          checkbox.closest("tr").classList.remove("table-active");
          document.getElementById("select-content").innerHTML = total;
        }
      };
    });
    var checkAll = document.getElementById("checkAll");
    if (checkAll) {
      checkAll.onclick = function () {
        var checkboxes = document.querySelectorAll(
          '.form-check-all input[type="checkbox"]'
        );
        if (checkAll.checked == true) {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = true;
            document.getElementById("selection-element").style.display =
              "block";
            document.getElementById("select-content").innerHTML =
              checkboxes.length;
            checkbox.closest("tr").classList.add("table-active");
          });
        } else {
          checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
            document.getElementById("selection-element").style.display = "none";
            checkbox.closest("tr").classList.remove("table-active");
          });
        }
      };
    }
  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
    // Slider,
    Multiselect,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div v-if="false" class="col-xl-3 col-lg-4">
        <div class="card">
          <div class="card-header">
            <div class="d-flex mb-3">
              <div class="flex-grow-1">
                <h5 class="fs-16">Filtres</h5>
              </div>
              <!-- <div class="flex-shrink-0">
                <a href="#" class="text-decoration-underline">Clear All</a>
              </div> -->
            </div>

            <div class="filter-choices-input">
              ->
              <Multiselect
                v-model="value"
                mode="tags"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :options="[
                  { value: 'Montres', label: 'Montres' },
                  { value: 'Casque', label: 'Casque' },
                  { value: 'Sweat-shirt', label: 'Sweat-shirt' },
                  { value: 'réduction de 20', label: 'réduction de 20' },
                  { value: '4 étoiles', label: '4 étoiles' },
                ]"
              />
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xl-12 col-lg-12">
        <div>
          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <router-link to="/add-product" class="btn btn-success"
                      ><i class="ri-add-line align-bottom me-1"></i> Ajouter un
                      Produit</router-link
                    >
                  </div>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-sm-end">
                    <div class="search-box ms-2">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Rechercher un produit..."
                        v-model="searchQuery"
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                    class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active fw-semibold"
                        data-bs-toggle="tab"
                        href="#productnav-all"
                        role="tab"
                      >
                        Tous
                        <span
                          class="badge badge-soft-danger align-middle rounded-pill ms-1"
                          >{{ productsData.length }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Sélectionner
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      ></div>
                      Resultat
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3 shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#removeItemModal"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Modal -->
            <div
              id="removeItemModal"
              class="modal fade zoomIn"
              tabindex="-1"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="btn-close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="mt-2 text-center">
                      <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="loop"
                        colors="primary:#f7b84b,secondary:#f06548"
                        style="width: 100px; height: 100px"
                      ></lord-icon>
                      <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Êtes-vous certain ?</h4>
                        <p class="text-muted mx-4 mb-0">
                          Êtes-vous certain de vouloir effacer tous ces produits
                          ?
                        </p>
                      </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                      <button
                        type="button"
                        class="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                      >
                        Fermer
                      </button>
                      <button
                        type="button"
                        class="btn w-sm btn-danger"
                        id="delete-record"
                        data-bs-dismiss="modal"
                        @click="handleDeleteMany"
                      >
                        Oui, Supprimer !
                      </button>
                    </div>
                  </div>
                </div>
                <!-- /.modal-content -->
              </div>
              <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->

            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div
                  class="tab-pane active"
                  id="productnav-all"
                  role="tabpanel"
                >
                  <div
                    id="table-product-list-all"
                    class="table-card table-responsive gridjs-border-none"
                  >
                    <table
                      class="table align-middle table-nowrap mb-0"
                      id="customerTable"
                    >
                      <thead class="table-light">
                        <tr>
                          <th scope="col" style="width: 50px">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkAll"
                                value="option"
                              />
                            </div>
                          </th>
                          <th>Produits</th>
                          <th>Stock</th>
                          <th>Prix</th>
                          <th>Commandes</th>
                          <th>Évaluation</th>
                          <th>Publié le</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr
                          class="gridjs-tr"
                          v-for="(data, index) of resultQuery"
                          :key="index"
                        >
                          <td
                            data-column-id="productListAllCheckbox"
                            class="gridjs-td"
                          >
                            <input
                              type="checkbox"
                              name="chk_child"
                              class="form-check-input check-item"
                            />
                          </td>
                          <td class="id" hidden>
                            <a href="">{{ data.id }}</a>
                          </td>
                          <td data-column-id="product" class="gridjs-td">
                            <span>
                              <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 me-3">
                                  <div class="avatar-sm bg-light rounded p-1">
                                    <img
                                      :src="data.image_src"
                                      alt=""
                                      class="img-fluid d-block"
                                    />
                                  </div>
                                </div>
                                <div class="flex-grow-1">
                                  <h5 class="fs-14 mb-1">
                                    <router-link
                                      :to="'/product-details/' + data.id"
                                      class="text-dark"
                                      >{{ data.name }}
                                    </router-link>
                                  </h5>
                                  <p class="text-muted mb-0">
                                    Catégories :
                                    <span class="fw-medium">{{
                                      data.category
                                    }}</span>
                                  </p>
                                </div>
                              </div>
                            </span>
                          </td>
                          <td data-column-id="stock" class="gridjs-td">
                            {{ data.stock }}
                          </td>
                          <td data-column-id="price" class="gridjs-td">
                            {{ $formatPrice(data.sale_price) }}
                          </td>
                          <td data-column-id="orders" class="gridjs-td">
                            {{ data.orders }}
                          </td>
                          <td data-column-id="rating" class="gridjs-td">
                            <span
                              ><span
                                class="badge bg-light text-body fs-12 fw-medium"
                                ><i class="mdi mdi-star text-warning me-1"></i
                                >{{ data.ratings }}</span
                              ></span
                            >
                          </td>
                          <td data-column-id="published" class="gridjs-td">
                            <span
                              >{{ data.publishedDate
                              }}<small class="text-muted ms-1">{{
                                data.publishedtime
                              }}</small></span
                            >
                          </td>
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button
                                  class="btn btn-soft-secondary btn-sm dropdown"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <router-link
                                      class="dropdown-item"
                                      :to="'/product-details/' + data.id"
                                      ><i
                                        class="ri-eye-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Voir</router-link
                                    >
                                  </li>
                                  <li>
                                    <router-link
                                      class="dropdown-item"
                                      :to="'/product-update/' + data.id"
                                      ><i
                                        class="ri-pencil-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Éditer</router-link
                                    >
                                  </li>
                                  <li class="dropdown-divider"></li>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      @click="deletedata(data)"
                                    >
                                      <i
                                        class="ri-delete-bin-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Supprimer</a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="d-flex justify-content-end m-3">
                      <div class="pagination-wrap hstack gap-2">
                        <a
                          class="page-item pagination-prev disabled"
                          href="#"
                          v-if="page != 1"
                          @click="page--"
                        >
                          Previous
                        </a>
                        <ul class="pagination listjs-pagination mb-0">
                          <li
                            v-for="(pageNumber, index) in pages.slice(
                              page - 1,
                              page + 5
                            )"
                            :key="index"
                            @click="page = pageNumber"
                            :class="{
                              active: pageNumber == page,
                              disabled: pageNumber == '...',
                            }"
                          >
                            <a class="page" href="#">{{ pageNumber }}</a>
                          </li>
                        </ul>
                        <a
                          class="page-item pagination-next"
                          href="#"
                          @click="page++"
                          v-if="page < pages.length"
                        >
                          Next
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="productnav-published" role="tabpanel">
                  <div
                    id="table-product-list-published"
                    class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="productnav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Desole! Aucun résultat trouvé</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
